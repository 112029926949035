const getDefaultState = () => {
  return {
    newsList: {
      totalCount: 0,
      newsInfoList: []
    },
    popular: {
      daily: [],
      weekly: [],
      monthly: []
    },
    masterData: {
      category: [
        {
          label: 'การลงทุน',
          value: '60ad3cae-ba3d-4405-af14-3ed4af1e5065',
          isSETNews: false
        },
        {
          label: 'ข่าว SET',
          value: 'SETNEWS',
          isSETNews: true
        },
        {
          label: 'หุ้น',
          value: 'S',
          isSETNews: true
        },
        {
          label: 'หน่วยลงทุน',
          value: 'U',
          isSETNews: true
        },
        {
          label: 'ETF',
          value: 'L',
          isSETNews: true
        },
        {
          label: 'DW',
          value: 'V',
          isSETNews: true
        },
        {
          label: 'DR',
          value: 'X',
          isSETNews: true
        },
        {
          label: 'DRx',
          value: 'XF',
          isSETNews: true
        },
        {
          label: 'อนุพันธ์',
          value: 'TFEX',
          isSETNews: true
        },
        // {
        //   label: 'ตลาดตราสารหนี้',
        //   value: 'TBX',
        //   isSETNews: true
        // },
        {
          label: 'กองทุนรวม',
          value: 'bcb41578-9ccf-42b4-bbd3-df8e6bb21d87',
          isSETNews: false
        }
      ],
      sourceCMS: [],
      sourceSET: [],
      type: [],
      securityType: [],
      allowedPeriod: {},
      latestdate: null
    },
    newsDetail: {}
  }
}

const state = getDefaultState

const mutations = {
  setNewsFromCMS (state, data) {
    state.newsList.totalCount = data.totalCount
    state.newsList.newsInfoList = data.items
  },
  setSourceCMS (state, data) {
    state.masterData.sourceCMS = data
  },
  setSourceSET (state, data) {
    state.masterData.sourceSET = data
  },
  setType (state, data) {
    state.masterData.type = data
  },
  setSecurityType (state, data) {
    state.masterData.securityType = data
  },
  setAllowedPeriod (state, data) {
    state.masterData.allowedPeriod = data
  },
  setLatestdate (state, data) {
    state.masterData.latestdate = data
  },
  setDaily (state, data) {
    state.popular.daily = data
  },
  setWeekly (state, data) {
    state.popular.weekly = data
  },
  setMonthly (state, data) {
    state.popular.monthly = data
  },
  setNewsDetail (state, data) {
    state.newsDetail = data
  }
}

const actions = {
  async searchNewsCMS ({ commit }, payload) {
    try {
      const params = new URLSearchParams(payload)
      const cmsUrl = '/api/cms/v1/news/all'
      const result = await this.$apiCms
        .get(cmsUrl, { params })
        .then((res) => {
          return res.data
        })
        .catch(err => err)
      commit('setNewsFromCMS', result)
    } catch (err) {
      commit('setNewsFromCMS', null)
    }
  },
  async getType ({ commit }) {
    try {
      const result = await this.$axios.$get('/api/set/news/type')
      commit('setType', result.types)
    } catch (error) {
      commit('setType', [])
    }
  },
  async getSourceSET ({ commit }) {
    try {
      const result = await this.$axios.$get('/api/set/news/source')
      commit('setSourceSET', result)
    } catch (error) {
      commit('setSourceSET', [])
    }
  },
  async getSecurityType ({ commit }) {
    try {
      const result = await this.$axios.$get(
        '/api/set/news/condition/security-type?website=STT'
      )

      if (!result.includes(item => item.id === 'TBX')) {
        result.push({
          id: 'TBX',
          nameTh: 'TBX',
          nameEn: 'TBX',
          type: 'market'
        })
      }

      commit('setSecurityType', result)
    } catch (error) {
      commit('setSecurityType', [])
    }
  },
  async getCategory ({ commit }) {
    try {
      const result = await this.$apiCms
        .get('/api/cms/v1/news/categories')
        .then((res) => {
          return res.data
        })
        .catch(() => [])
      commit('setCategory', result)
    } catch (error) {
      commit('setCategory', [])
    }
  },
  async getSourceCMS ({ commit }) {
    try {
      const result = await this.$apiCms
        .get('/api/cms/v1/news/sources')
        .then((res) => {
          return res.data
        })
        .catch(() => [])
      commit('setSourceCMS', result)
    } catch (error) {
      commit('setSourceCMS', [])
    }
  },
  async getAllowedPeriod ({ commit }) {
    try {
      const result = await this.$axios.$get('/api/set/news/allowed-period')
      commit('setAllowedPeriod', result)
    } catch (error) {
      commit('setAllowedPeriod', {})
    }
  },
  async getLatestdate ({ commit }) {
    try {
      const result = await this.$axios.$get('/api/set/news/latestdate')
      commit('setLatestdate', result.datetime)
    } catch (error) {
      commit('setLatestdate', new Date())
    }
  },
  async getPopular ({ commit }, frequency) {
    const params = {
      frequency,
      language: 'TH',
      // newsSource: 'CMS|RSS',
      pageIndex: 0,
      pageSize: 5
    }
    const response = await this.$apiCms
      .get('/api/cms/v1/news/popular', { params })
      .then((res) => {
        const newItems = res.data.items
        newItems.forEach((item) => {
          if (item.newsSource === 'SETAPI') {
            const { uuid, symbol } = item
            const newsPath = `${this.$i18n.locale}/news-and-articles/news/${uuid}?symbol=${encodeURIComponent(symbol)}`
            const url = new URL(newsPath, process.env.WEB_HOST)
            item.url = url.toString()
          }
        })
        return newItems
      })
      .catch((e) => { return [] })

    if (frequency === 'Daily') {
      commit('setDaily', response)
    } else if (frequency === 'Weekly') {
      commit('setWeekly', response)
    } else {
      commit('setMonthly', response)
    }
  },
  async getNewsDetail ({ commit }, payload) {
    const { newsID } = payload
    const newsDetailOption = {
      params: {
        symbol: payload.symbol,
        lang: this.$i18n.locale
      }
    }
    let symbol = ''
    const response = await this.$axios
      .$get(`/api/set/news/${newsID}/detail`, newsDetailOption)
      .then(result => result)
      .catch(() => null)
    symbol = response !== null ? response.symbol : ''
    const info = await this.$axios
      .$get(`/api/set/stock/${symbol}/info`)
      .then(result => result)
      .catch(() => {})
    commit('setNewsDetail', { ...response, ...info })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
