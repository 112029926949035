//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCategoryText } from '~/helpers/virtualport/helpers.js'
import { showRequestNum, hideRequestNum } from '@/directives/modal'
export default {
  name: 'MiscMemberVirtualPortfolioModalPortfolioName',
  directives: {
    showRequestNum,
    hideRequestNum
  },
  props: {
    showRequestNum: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: 'แก้ไขชื่อพอร์ตการลงทุน'
    },
    category: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: 'edit'
    },
    submitCallback: {
      type: Function,
      default: () => Promise.resolve(true)
    }
  },
  data () {
    return {
      lockBtn: false,
      hideRequestNum: 0,
      formData: {
        name: ''
      },
      validationState: {
        name: {}
      },
      limit: 25
    }
  },
  computed: {
    characters () {
      const { name } = this.formData
      const char = name ? name.length : 0
      return char + ' / ' + this.limit + ' ตัวอักษร'
    }
  },
  watch: {
    'formData.name' () {
      this.validationState.name.errorLevel = 0
      this.validationState.name.errorType = undefined
    },
    showRequestNum (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData = {
          name: this.$virtualPort.state[this.category]?.name
        }
        this.validationState = {
          name: { errorLevel: 0, errorType: undefined }
        }
      }
    }
  },
  methods: {
    hideModal () {
      this.hideRequestNum++
    },
    async onSubmit () {
      let validatedFail = false
      const { name } = this.formData //eslint-disable-line
      const validationState = this.validationState
      checkName()
      if (!validatedFail) {
        this.lockBtn = true
        const result = await this.submitCallback({
          category: this.category,
          name
        })
        if (result) {
          this.$toast.success(
            'success',
            this.action === 'edit'
              ? 'แก้ไขชื่อพอร์ตการลงทุนเรียบร้อยแล้ว'
              : 'สร้างชื่อพอร์ตการลงทุนเรียบร้อยแล้ว'
          )
          this.hideModal()
          if (this.action === 'edit') {
            this.dotry(() =>
              this.$personalized.clickElement({
                name: 'memberVirtualPortfolio5',
                optionLang: {
                  category: getCategoryText(this.category),
                  text: 'แก้ไขชื่อพอร์ต'
                }
              })
            )
          } else {
            this.$personalized.clickElement({
              name: 'memberVirtualPortfolio13',
              optionLang: {
                category: getCategoryText(this.category)
              }
            })
          }
        } else {
          this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
        }
        this.lockBtn = false
      }

      function checkName () {
        if (!name || name === '') {
          validatedFail = true
          validationState.name.errorLevel = 2
          validationState.name.errorType = 'required'
          return //eslint-disable-line
        }
      }
    },
    onInput (e) {
      const elementValue = e.target.value
      if (this.validateInput(elementValue)) {
        this.formData.name = elementValue
      } else {
        e.target.value = this.formData.name ?? ''
      }
    },
    validateInput (string) {
      const regex = /^[A-Za-z0-9ก-๛\s]*$/g
      return regex.test(string)
    }
  }
}
